import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import PageRoot from "../components/PageRoot"
import AtsGrid from "../components/AtsGrid"
import GiftCardTile from "../components/GiftCardTile"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { Empty } from "antd"

const GiftCardsPage = ({ location, data, navigate, navigation }) => {
  const { t } = useTranslation()
  const giftCards = data.ats.gift_cards

  const renderItem = (item, index) => {
    const { seo } = item

    return (
      <GiftCardTile
        data={item}
        type="full"
        onClick={() => {
          navigate(`/gift-card/${seo.slug}`)
        }}
      />
    )
  }

  return (
    <PageRoot title={t("label:allGiftCards")} showTitle>
      <ContainerWithPadding>
        <AtsGrid
          colSettings={{ xs: 24, sm: 12, md: 6, lg: 6 }}
          dataSource={giftCards}
          emptyItem={<Empty description={t("label:noGiftCardsAvailable")} />}
          renderItem={renderItem}
        />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query allGiftCards {
    ats {
      gift_cards(
        order_by: { title: asc }
        where: { state: { _eq: PUBLISHED } }
      ) {
        ...PreviewCardGiftCard
      }
    }
  }
`

export default GiftCardsPage
